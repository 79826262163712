@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSedenavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-white;
  }
}

nav .active li {
  color: black;
  font-weight: 600;
  text-decoration: 1px underline black;
}

@media screen and (max-width: 665px) {
  nav .active li {
    font-weight: 600;
  }
}

::-webkit-scrollbar{
  display: none;
}

  @font-face {
    font-family: "nourd";
    src: url("./assets//fonts//nourd_regular.ttf");
    font-weight: normal;
    font-style: normal;
  }
  
  .nourd-text {
    font-family: "nourd" !important;
  }
  
  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  td {
    background: #f6f8fa;
  }

  tr td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  tr td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  tr th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  tr th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }